import React from "react"
import Layout from "../../components/layout/Layout"
import "./PrivacidadConfidencialidad.css"

export default () => {
  return (
    <Layout type="HMNF" title="Privacidad y confedencialidad">
      <div className="privacy-content">
        <h2>PRIVACIDAD Y CONFIDENCIALIDAD</h2>
        <p>
          Gracias por visitar kidsmadehere.com (el “Sitio”) operada por Taller
          de Confecciones San Luis S.A. (“TCSL”). Somos conscientes de la
          importancia que representa para usted, el uso y tratamiento que le
          damos a su información personal y el modo de como la compartimos, por
          ello queremos agradecerle por su confianza depositada por lo que nos
          comprometemos a utilizar su información con el debido cuidado y
          prudencia.
        </p>
        <p>
          Nuestra Política de Privacidad explica cómo recolectamos, utilizamos y
          (bajo ciertas condiciones) divulgamos su información personal. Esta
          Política de Privacidad también explica las medidas que hemos tomado
          para asegurar su información personal. Por último, esta política de
          privacidad explica los procedimientos que seguimos frente a la
          recopilación, uso y divulgación de su información personal.
        </p>
        <h3>
          ¿Qué datos personales recopila el Sitio y para qué fines son
          utilizados?
        </h3>
        <p>
          La información personal que recopilamos de nuestros clientes nos ayuda
          a personalizar y mejorar continuamente su experiencia en el Sitio.
          Utilizamos esta información para procesar pedidos, entregar productos
          y servicios, procesar pagos, mantener y actualizar nuestros archivos y
          sus cuentas con nosotros, hacer disponible contenido tales como listas
          de deseos y comentarios de los clientes, recomendaciones de productos
          y servicios de interés para usted. Utilizamos esta información para
          poder brindar estos servicios y funciones en nuestro propio nombre.
          También utilizamos esta información para mejorar nuestros servicios,
          características y plataforma, prevenir o detectar fraude o abuso, y
          permitir a terceros prestar servicios técnicos, logístico o de otro
          tipo en nuestro nombre. También utilizamos esta información para
          adaptar nuestras comunicaciones, como por ejemplo mediante el envío de
          mensajes de correo electrónico con ofertas y promociones que son más
          relevantes para los clientes
        </p>
        <p>Estos son los tipos de información que recopilamos:</p>
        <p>
          Información que Usted Nos Proporciona: Recibimos y almacenamos toda la
          información que usted introduce en nuestra página Web o que nos
          facilita por cualquier otro medio. La información que usted nos
          proporciona nos es útil para responder a sus peticiones, personalizar
          futuras compras que vaya a realizar, mejorar nuestras tiendas y
          ponernos en contacto con usted.
        </p>
        <p>
          Información Automática: Recibimos y almacenamos ciertos tipos de
          información, cada vez que usted interactúa con nosotros. Por ejemplo,
          al igual que hacen muchas otras páginas Web, utilizamos las "cookies"
          para recabar ciertos tipos de información cuando su navegador de
          Internet accede a El Sitio o a publicidad u otros tipos de contenidos
          que le son ofrecidos por parte de o en nombre de El Sitio en otras
          páginas Web.
        </p>
        <p>
          Móvil: La mayoría de dispositivos móviles proporcionan a los usuarios
          la posibilidad de desactivar los servicios de localización. Lo más
          probable es que dicha posibilidad se encuentre en el menú de
          configuración del dispositivo. Si tiene dudas acerca de cómo
          desactivar los servicios de localización de su dispositivo, le
          recomendamos que se ponga en contacto con su operador de servicios
          móviles o con el fabricante de su dispositivo.
        </p>
        <p>
          Información Recabada de Otras Fuentes: Algunos ejemplos de la
          información que recabamos de otras fuentes incluyen información
          actualizada sobre la dirección de entrega recabada de nuestros
          transportistas o de terceros, la cual utilizamos para corregir
          nuestros archivos y realizar la entrega de su próxima compra o una
          mejor comunicación; información sobre su cuenta, sobre compras y
          canjees y sobre sus visitas a la página Web, que recabamos de empresas
          con las que desarrollamos actividades conjuntas bajo la misma marca o
          a los que ofrecemos servicios técnicos, logísticos o publicitarios,
          entre otros; información sobre términos de búsqueda y resultados de
          las búsquedas realizadas a través de aplicaciones de búsqueda de
          Internet ofrecidas por nuestra subsidiaria, Alexa Internet; enlaces y
          resultados de búsqueda, incluidos listados de pago (tales como los
          Enlaces Patrocinados); e información sobre el historial de crédito de
          los burós de crédito que utilizamos para prevenir y detectar el fraude
          y para ofrecer servicios de crédito y financieros a determinados
          clientes.
        </p>
        <p>
          Información a la que Puede Acceder: Ejemplos de información a la que
          tiene acceso fácilmente desde El Sitio incluyen: información
          actualizada sobre pedidos; información personalmente identificable
          (incluyendo nombre, correo electrónico, contraseña, preferencias de
          comunicaciones personalizada, libreta de direcciones y configuraciones
          de "1-Clic"); preferencias de pago (incluyendo información sobre
          tarjetas de crédito, saldos de cupones de regalo y promocionales);
          configuraciones de avisos por correo electrónico (incluyendo e-mails
          de marketing y boletines); Recomendaciones (incluyendo Recomendaciones
          para Usted y Mejora tus Recomendaciones); listas de compra; y Mi
          perfil (incluyendo sus Opiniones de cliente, Recomendaciones, listas
          denominadas "Listmania", y perfil personal).
        </p>
        <h3>¿Qué son las Cookies?</h3>
        <p>
          Ejemplos de información a la que tiene acceso fácilmente desde El
          Sitio incluyen: información actualizada sobre pedidos; información
          personalmente identificable (incluyendo nombre, correo electrónico,
          contraseña, preferencias de comunicaciones personalizada, libreta de
          direcciones y configuraciones de "1-Clic"); preferencias de pago
          (incluyendo información sobre tarjetas de crédito, saldos de cupones
          de regalo y promocionales); configuraciones de avisos por correo
          electrónico (incluyendo e-mails de marketing y boletines);
          Recomendaciones (incluyendo Recomendaciones para Usted y Mejora tus
          Recomendaciones); listas de compra; y Mi perfil (incluyendo sus
          Opiniones de cliente, Recomendaciones, listas denominadas "Listmania",
          y perfil personal).
        </p>
        <p>..........</p>
        <h3>AUTORIDAD</h3>
        <p>
          Si el usuario/cliente considera que han sido vulnerados sus derechos
          respecto de la protección de datos personales, tiene el derecho de
          acudir a la autoridad correspondiente para defender su ejercicio. La
          autoridad es la Autoridad Nacional de Protección de Datos Personales
          (APDP) solicitando la tutela de sus derechos, su sitio web es{" "}
          <a href="https://www.minjus.gob.pe/?s=proteccion+de+datos+personales">
            www.minjus.gob.pe/proteccion-de-datos-personales/
          </a>
          .
        </p>
      </div>
    </Layout>
  )
}
